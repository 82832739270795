import React from 'react';
import PushToTalk from './PushToTalk';

function App() {
  return (
    <div className="App">
      <PushToTalk />
    </div>
  );
}

export default App;
